import React from 'react'
import './Playzoneau.css'; 
import nlFlg from '../images/nlFlg.png'
import back2 from '../images/back2.jpg'
import back3 from '../images/back3.jpg'
import back4 from '../images/back4.jpg'
import back5 from '../images/back5.jpg'
import background from '../images/back7.jpg'
import { Helmet } from 'react-helmet-async';
import favicon from './../images/nlFlg.png'

function NetherlandsGuideWin() {
  return (
    <div>
        <Helmet>
                <title>Best casino hotels in Netherlands</title>
                <meta name="description" content="Best casino hotels in Netherlands" />
                <link rel="icon" type="image/x-icon" href={favicon} />
            </Helmet>
         <nav class="navbar navbar-expand-lg sticky-top bg-white">
       <div class="container">
           <a class="navbar-brand" href="#"><img src={nlFlg} class="top-logo" alt="logo.com"/> <span>Travel agency in Netherlands</span> </a>
           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
           </button>
           <div class="collapse navbar-collapse" id="navbarSupportedContent">
               <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                   <li class="nav-item">
                       <a class="nav-link nohover" href="#home">Home</a>
                   </li>
                   <li class="nav-item">
                       <a class="nav-link nohover" href="#list">List</a>
                   </li>
                   <li class="nav-item">
                       <a class="nav-link nohover" href="#contact-us">Contact us</a>
                   </li>
               </ul>
           </div>
       </div>
   </nav>







   <section id="home" class="px-5 py-6 py-xxl-10 hcf-bp-center hcf-bs-cover hcf-overlay hcf-transform" style={{background:`url(${background})`}}>
       <div class="container">
           <div class="row justify-content-md-center">
               <div class="col-12 col-md-11 col-lg-9 col-xl-7 col-xxl-6 text-center text-white">
                   <h3 class="fw-bold mb-3 Playfair-font hero-title" style={{color:"white"}}>Casino hotels in Netherlands</h3>
                   <p>Dreaming of a getaway that combines the allure of luxury accommodations with the exhilaration of casino gaming? Look no further – Netherlands boasts a selection of exceptional casino hotels that promise an unforgettable escape. Whether you're seeking a rooftop oasis or a lavish suite with breathtaking views, these handpicked destinations offer the perfect blend of opulence, entertainment, and the chance to test your luck at the tables.</p>
               </div>
           </div>
       </div>
   </section>


   <section id="list" class="hotel section-padding padding-top-80">
       <div class="container">
           <div class="row">
               <div class="col-md-12">
                   <div class="section-howtouse text-left pb-5">
                       <div class="container-body">

                           <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-2">
                               <div class="col mb-4">
                                   <div class="card list-hover">
                                       <img src={back2} class="card-img-top" alt="..."/>
                                       <div class="card-body">
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star"></span>
                                           <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">The Heeren van Noortwyck Casino Hotel</a></h5>
                                           <p class="card-text">For those who cherish tranquility and gaming excitement in equal measure, The Heeren van Noortwyck & Casino offers the perfect balance. Unwind in minimalist yet stylish rooms, allowing your mind to clear before immersing yourself in a world of gaming delights. From 9 am to 5 am, discover the thrill of 370 gaming machines and over 20 table games, ensuring endless entertainment throughout your stay. Yet, The Heeren van Noortwyck is more than a gaming destination – it's a haven of romantic serenity. Enveloped in a Zen-like atmosphere, it's an idyllic setting for an intimate retreat. Delight in soothing dining experiences, relishing the harmonious ambiance that accompanies your every moment. Consider The Heeren van Noortwyck for weddings and romantic events, as it's an enchanting backdrop that fosters cherished memories.

                                           </p>
                                           <a href="#" class="list-btn">Book now</a>
                                       </div>
                                       <div class="card-body">
                                           <div class="booking-rating">
                                               <div class="verified-point">
                                                   8.6
                                               </div>
                                               <div class="rating-info">
                                                   <h4>Very good</h4>
                                                   <p>100% verified reviews</p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>
                               </div>
                               <div class="col mb-4">
                                   <div class="card list-hover">
                                       <img src={back4} class="card-img-top" alt="..."/>
                                       <div class="card-body">
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star"></span>
                                           <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Van der Valk Casino Hotel Nuland</a></h5>
                                           <p class="card-text">Seeking a regal escape? Van der Valk Hotel Nuland is your answer. Luxuriate in lavish suites that redefine opulence, each room a sanctuary of refined comfort. But the true allure lies beyond your suite's door. Venture into a world of culinary delights at the eight restaurants and bars, savoring delectable creations that tantalize your taste buds. Prioritize relaxation at the Azure Spa, where ultimate pampering awaits before you venture into the captivating casino arena. With 70 table games and a staggering 1400 gaming machines, the thrill of the game is only a heartbeat away. Engage in ongoing tournaments or retreat to the exclusive Sovereign VIP gambling lounge, ensuring that every moment is a memorable one. The modern enthusiast will appreciate the array of electronic gaming machines and table games available 24/7.</p>
                                           <a href="#" class="list-btn">Book now</a>
                                       </div>
                                       <div class="card-body">
                                           <div class="booking-rating">
                                               <div class="verified-point">
                                                   8.6
                                               </div>
                                               <div class="rating-info">
                                                   <h4>Very good</h4>
                                                   <p>100% verified reviews</p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>
                               </div>
                               <div class="col mb-4">
                                   <div class="card list-hover">
                                       <img src={back3} class="card-img-top" alt="..."/>
                                       <div class="card-body">
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star"></span>
                                           <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Van der Valk  Gilze Casino Hotel</a></h5>
                                           <p class="card-text">Indulge in the epitome of sophistication at Van der Valk Hotel Gilze Casino. Imagine basking in a rooftop pool fringed by swaying palm trees, all while gazing at the stunning reef vista. Your accommodations go beyond the ordinary, featuring rooms complete with jacuzzi spas and private balconies offering panoramic views. Immerse yourself in a world of exquisite dining options, from artisanal cocktails to fine dining experiences, all setting the stage for your gaming adventure. Step into the casino realm, where over 500 slot machines beckon alongside a diverse range of games, including roulette, baccarat, and blackjack. The excitement continues around the clock, allowing you to dive into the action from 10 am to 2 am. Even if you have little ones in tow, worry not – a complimentary babysitter is at your service.  </p>
                                           <a href="#" class="list-btn">Book now</a>
                                       </div>
                                       <div class="card-body">
                                           <div class="booking-rating">
                                               <div class="verified-point">
                                                   8.6
                                               </div>
                                               <div class="rating-info">
                                                   <h4>Very good</h4>
                                                   <p>100% verified reviews</p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>
                               </div>
                               <div class="col mb-4 ">
                                   <div class="card list-hover">
                                       <img src={back5} class="card-img-top" alt="..."/>
                                       <div class="card-body">
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star checked-star"></span>
                                           <span class="fa fa-star"></span>
                                           <h5 class="card-title Playfair-font"><a href="#" class="list-link Playfair-font">Casino Hotel Hilversum - de Witte Bergen</a></h5>
                                           <p class="card-text">Hotel Hilversum - de Witte Bergen beckons with an array of choices, whether you yearn for cityscape vistas or river panoramas. Revel in the lap of luxury with sumptuous villas boasting expansive windows that frame the breathtaking views. If you seek affordability without compromising style, the thoughtfully adorned rooms will surely captivate your senses. Feast on a rich tapestry of culinary offerings, from diverse cuisines to specialty beverages, while the bar exudes an atmosphere reminiscent of classic films. Prepare to be enthralled by the sheer scale of Hotel Hilversum - de Witte Bergen's sprawling casino floor spanning 220,000 square feet. Navigate your way through royal table games and hundreds of slots, or take advantage of scheduled promotions that elevate your gaming experience. Even for the curious observer, the facility is a sight to behold, an exploration of entertainment that transcends the ordinary. Your extraordinary casino hotel getaway awaits – a fusion of indulgence, excitement, and relaxation that promises to redefine your travel experience. Book your escape today and embrace the allure of Netherlands's finest casino hotels. </p>
                                           <a href="#" class="list-btn">Book now</a>
                                       </div>
                                       <div class="card-body">
                                           <div class="booking-rating">
                                               <div class="verified-point">
                                                   8.6
                                               </div>
                                               <div class="rating-info">
                                                   <h4>Very good</h4>
                                                   <p>100% verified reviews</p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>
                               </div>

                           </div>
                       </div>
                   </div>

               </div>
           </div>
       </div>
   </section>


   <section id="contact-us" class="hotel section-padding bg-light">
       <div class="container">
           <div class="row align-items-center">
               <div class="mapouter">
                   <div class="gmap_canvas">
                       <iframe width="100%" height="560" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11984.056463345145!2d4.916102077149423!3d52.391162236177465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1saz!2saz!4v1731670575718!5m2!1saz!2saz" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br/>
                   </div>
               </div>
           </div>
       </div>
   </section>





   <section class="why-us section-padding bg-white">
       <div class="container">
           <div class="row">

               <div class="row">
                   <div class="col-md-12">
                       <div class="section-howtouse text-left">
                           <div class="container-body Playfair-font">
                               <h1 class="Playfair-font section-title">More interesting places</h1>
                           </div>
                           <div class="container-body">

                               <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-3">
                                   <div class="col mb-4">
                                       <div class="footer-links">
                                           <ul>
                                               <li>
                                                   <a href="#" class="footer-link-tag">	The Ville Resort & Casino</a>
                                               </li>

                                               <li>
                                                   <a href="#" class="footer-link-tag">	Pullman Reef Hotel Casino</a>
                                               </li>

                                           </ul>
                                       </div>
                                   </div>
                                   <div class="col mb-4">
                                       <div class="footer-links">
                                           <ul>
                                               <li>
                                                   <a href="#" class="footer-link-tag">	The Star Gold Coast</a>
                                               </li>
                                               <li>
                                                   <a href="#" class="footer-link-tag">	Crown Melbourne</a>
                                               </li>


                                           </ul>
                                       </div>
                                   </div>
                                   <div class="col mb-4">
                                       <div class="footer-links">
                                           <ul>

                                               <li style={{color:"#717171"}}>
                                                   General inquiries: info@NetherlandsGuideWin.com

                                               </li>
                                               <li style={{color:"#717171"}}>
                                                   Travel inquiries: travel@NetherlandsGuideWin.com

                                               </li>
                                           </ul>
                                       </div>
                                   </div>


                               </div>
                           </div>
                       </div>

                   </div>
               </div>

           </div>
       </div>
   </section>

   <footer class="bg-light p-2 text-center">
       <div class="container padding-20">
           <div class="social-icon">
               <a href="#" class="fa fa-facebook"></a>
               <a href="#" class="fa fa-instagram"></a>
           </div>
           <a href='#' style={{color:"#717171", textDecoration:"none",margin:"10px 0",display:"block"}}>Privacy Policy</a>
           <p class="text-dark footer-text">© 2023 Copyright NetherlandsGuideWin
.com All rights reserved.</p>
       </div>
   </footer>
    </div>
  )
}

export default NetherlandsGuideWin

